import { GameMode } from "@/types"

import {
  getTodaysIndex,
  getTodaysMessage,
  getTodaysWord,
} from "@lapalabradeldia/core/solutions/normal"

import SingleGame from "@/components/game/single/single-game"

interface Props {
  title: string
  subtitle?: string
}

export default function ClientPage(props: Props) {
  const gameID = getTodaysIndex()
  const solution = getTodaysWord(gameID)
  const message = getTodaysMessage(gameID)

  return (
    <SingleGame
      title={props.title}
      subtitle={props.subtitle}
      mode={GameMode.Normal}
      id={gameID}
      solution={solution}
      message={message}
      tildes={false}
    />
  )
}
